import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MediaGrid from "../components/media-grid";
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

const IndexPage = ({data}) => {
  return (
    <Layout>
      <SEO title="Home" />
      <MediaGrid data={data.kenticoCloudItemPage.elements.media}/>
    </Layout>
  )
}

export const query = graphql`
query homeQuery {
  kenticoCloudItemPage(id: { eq: "fd6d2c9f-20d6-5d7f-b556-d4684e19d30f" } ) {
    elements {
      media {
        elements {
          media_asset {
            value {
              url
            }
          }
          youtube_id {
            value
          }
          format {
            value {
              codename
            }
          }
          caption {
            value
          }
          type {
            value {
              codename
            }
          }
        }
      }
      content {
        resolvedHtml
      }
      page_title {
        value
      }
    }
  }
}
`;

IndexPage.propTypes = {
  data: PropTypes.object,
};

export default IndexPage
